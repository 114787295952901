import React from 'react';
import { Menu, Button } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { arrayOf, string, number } from 'prop-types';

const NavMenu = ({ terms, languageId }) => (
  <Menu secondary inverted stackable>
    <Menu.Item>
      <NavLink to="/ministries" activeClassName="selected">
        {terms[1]}
      </NavLink>
    </Menu.Item>
    {languageId === 1 && (
      <>
        <Menu.Item>
          <NavLink to="/articles" activeClassName="selected">
            Articles
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink to="/prayer-letters" activeClassName="selected">
            Prayer Letters
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink to="/about" activeClassName="selected">
            About
          </NavLink>
        </Menu.Item>
      </>
    )}
    <Menu.Item>
      <NavLink to="/languages" activeClassName="selected">
        {terms[2]}
      </NavLink>
    </Menu.Item>
    {languageId === 1 && (
      <Menu.Item id="donate">
        <Button
          inverted
          basic
          as="a"
          href="https://twr.org/project/cross-currents-international-ministries"
          content="Donate"
          icon="heart"
        />
      </Menu.Item>
    )}
  </Menu>
);

NavMenu.propTypes = {
  terms: arrayOf(string).isRequired,
  languageId: number.isRequired
};

export default NavMenu;
